* {
    margin: 0px;
    padding: 0px;
    border: none;
    outline: none;
}

/***

====================================================================
	Global Settings
====================================================================

 ***/

body {
    font-family: "Noto Sans TC", sans-serif;
    font-size: 14px;
    color: #777777;
    line-height: 1.7em;
    font-weight: 400;
    background: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: #2acee8;
}

button,
a:hover,
a:focus,
a:visited {
    text-decoration: none;
    outline: none !important;
}

h2,
h4,
h5 {
    position: relative;
    font-weight: normal;
    margin: 0px;
    background: none;
    line-height: 1.6em;
    font-family: "Noto Sans TC", sans-serif;
}


p {
    position: relative;
    line-height: 1.8em;
}

/* Typography */

h1 {
    font-size: 2.1875em;
    font-weight: 700;
    /* Approximately 35px */
}

h2 {
    font-size: 1.75em;
    /* Approximately 28px */
}

h3 {
    font-size: 1.5em;
    /* Approximately 24px */
}

h4 {
    font-size: 1.125em;
    /* Approximately 18px */
}

h5 {
    font-size: 1em;
    /* Approximately 16px */
}

@media only screen and (max-width: 600px) {
    body {
        font-size: 14px;
        /* Decrease the base font size for smaller screens */
    }

    h1 {
        font-size: 2em;
        /* Approximately 32px */
    }

    h2 {
        font-size: 1.625em;
        /* Approximately 26px */
    }

    h3 {
        font-size: 1.375em;
        /* Approximately 22px */
    }

    h4 {
        font-size: 1.125em;
        /* Keep h4 the same as on larger screens */
    }

    h5 {
        font-size: 1em;
        /* Keep h5 the same as on larger screens */
    }
}


.auto-container {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
}

.page-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
}

ul,
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

img {
    display: inline-block;
    max-width: 100%;
}

.theme-btn {
    cursor: pointer;
    display: inline-block;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.centered {
    text-align: center;
}

/* List Style One */

/* List Style Two */

/*Btn Style One*/

.btn-style-one {
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    padding: 17px 56px;
    font-weight: 500;
    overflow: hidden;
    border-radius: 3px;
    overflow: hidden;
    background-color: #245c8a;
    text-transform: uppercase;
    font-family: "Noto Sans TC", sans-serif;
}

.btn-style-one:before {
    position: absolute;
    content: "";
    left: 110%;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    background-color: #ffe00c;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    transform: skewX(-25deg);
}

.btn-style-one .txt {
    position: relative;
    z-index: 1;
}

.btn-style-one .txt:before {
    position: absolute;
    content: "";
    left: -30px;
    top: 10px;
    width: 21px;
    height: 4px;
}

.btn-style-one .txt:after {
    position: absolute;
    content: "";
    right: -30px;
    top: 10px;
    width: 21px;
    height: 4px;
}

.btn-style-one:hover::before {
    left: 0%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.btn-style-one:hover {
    color: #222222;
    border-color: #00e49a;
}

/* Btn Style Two */

.btn-style-two {
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    padding: 15px 56px;
    font-weight: 500;
    overflow: hidden;
    border-radius: 3px;
    overflow: hidden;
    background-color: #ea1836;
    text-transform: capitalize;
    font-family: "Noto Sans TC", sans-serif;
}

.btn-style-two:before {
    position: absolute;
    content: "";
    left: 110%;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    background-color: #ffe00c;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    transform: skewX(-25deg);
}

.btn-style-two .txt {
    position: relative;
    z-index: 1;
}

.btn-style-two .txt:before {
    position: absolute;
    content: "";
    left: -30px;
    top: 10px;
    width: 21px;
    height: 4px;
}

.btn-style-two .txt:after {
    position: absolute;
    content: "";
    right: -30px;
    top: 10px;
    width: 21px;
    height: 4px;
}

.btn-style-two:hover::before {
    left: 0%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.btn-style-two:hover {
    color: #222222;
    border-color: #ffe00c;
}

/* Btn Style Three */

/* Btn Style Four */

/* Btn Style Five */

/* Social Icon One */

.preloader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #ffffff;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(https://cdnbeta.explico.sg/v5/assets/images/explico-cmyk-tagline-logo@2x.png);
    background-size: 140px;
}

img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}

/***

====================================================================
	Section Title
====================================================================

***/

.sec-title {
    position: relative;
    /* margin-bottom: 55px; */
}

.sec-title .title {
    position: relative;
    color: #135d69;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
}

.sec-title h2,
.sec-title h3 {
    color: #06092d;
    font-weight: 700;
}

.sec-title.centered {
    text-align: center !important;
}

/***

====================================================================
	Main Header style
====================================================================

***/

.main-header {
    position: relative;
    z-index: 99;
    width: 100%;
}

.main-header .outer-box {
    position: absolute;
    /* right:0px; */
    right: 110px;
    margin-left: 20px;
    padding: 20px 0px;
}

/* Header Search Form */

/* Outer Box */

/* Header Lower */

/* Main Menu */

.main-menu {
    position: static;
    float: left;
    padding: 7px 0px;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.custom-navbar {
    background-color: #f5f8fc;
    border-bottom: 2px solid #cbd5e0;
}

.custom-navbar li a {
    color: #245e8e !important;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    opacity: 1;
    padding: 0;
    text-align: center;
    text-transform: capitalize;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

@media (min-width: 1200px) {
    .custom-navbar li a {
        padding: 22px 0;
        font-size: 16px;
    }

    .custom-navbar {
        padding-left: 100px;
        padding-right: 100px;
    }
}

.custom-navbar li a:hover {
    color: #dc3545 !important;
}

/************************ Mega Menu ***************************/

/* Sticky Header */

.sticky-header {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    left: 0px;
    top: 0px;
    width: 100%;
    padding: 0px 0px;
    background: #ffffff;
    z-index: 0;
    border-bottom: 1px solid #e8f1f7;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.sticky-header .logo {
    padding: 17px 0px 6px;
}

/***

====================================================================
	Hidden Sidebar style
====================================================================

***/

/***

====================================================================
	Mobile Menu
====================================================================

***/

.main-header .sticky-header .outer-box {
    margin-left: 20px;
    padding: 13px 0px;
}

/***

====================================================================
	Scroll To Top style
====================================================================

***/

.scroll-to-top {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    color: #ffffff;
    font-size: 13px;
    text-transform: uppercase;
    line-height: 38px;
    text-align: center;
    z-index: 100;
    cursor: pointer;
    background: #222222;
    display: none;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.scroll-to-top:hover {
    color: #ffffff;
    background: #000000;
}

/***

====================================================================
	Banner Section
====================================================================

***/

.banner-section {
    position: relative;
    overflow: hidden;
    /*padding-top:60px;
	padding-bottom:120px; by ds */
    /* background-image: url("../images_assests/pattern-21.png"); */
    background-size: 100% 100%;
}

/***

====================================================================
	Instructor Section
====================================================================

***/

/***

====================================================================
	Benefit Section
====================================================================

***/

/***

====================================================================
	Feature Section
====================================================================

***/

.feature-section {
    position: relative;
    overflow: hidden;
    /* padding:50px 0px 40px; */
    padding: 10px 0px 40px;
}

.feature-section .content-column {
    position: relative;
    margin-bottom: 40px;
}

.feature-section .content-column .inner-column {
    position: relative;
    padding-top: 45px;
    z-index: 1;
}

.feature-section .content-column .inner-column .sec-title {
    margin-bottom: 30px;
}

.feature-section .pattern-layer {
    position: absolute;
    right: 0px;
    top: 100px;
    width: 954px;
    height: 505px;
    background-repeat: no-repeat;
}

/***

====================================================================
	Courses Section
====================================================================

***/

.courses-section {
    position: relative;
    overflow: hidden;
    /* padding:30px 0px 40px; */
    padding: 0px 0px 0px;
}

.courses-section .pattern-layer {
    position: absolute;
    right: -80px;
    bottom: 450px;
    width: 774px;
    height: 416px;
}

.course-block {
    position: relative;
    margin-bottom: 30px;
}

.course-block .inner-box {
    position: relative;
}

.course-block .inner-box .image {
    position: relative;
    overflow: hidden;
    background-color: #2acee8;
}

.course-block .inner-box .lower-content {
    position: relative;
    padding: 30px 25px;
    background-color: #efeff3;
}

.course-block .inner-box .lower-content h4 {
    position: relative;
    font-weight: 700;
    line-height: 1.4em;
    padding-bottom: 20px;
    font-size: 20px;
    border-bottom: 1px solid #dadada;
}

.course-block .inner-box .lower-content h4 a {
    position: relative;
    color: #000000;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.course-block .inner-box .lower-content h4 a:hover {
    color: #2acee8;
}

.fixed-height-title {
    height: 90px;
    overflow: hidden;
}

.fixed-height-desc {
    height: 163px;
    overflow: hidden;
}

/***

====================================================================
	Courses Section
====================================================================

***/

/***

====================================================================
	Skill Section
====================================================================

***/

/* Skills Section */

/***

====================================================================
	Testimonial Section
====================================================================

***/

.testimonial-section {
    position: relative;
    overflow: hidden;
    padding: 40px 0px 60px;
}

.testimonial-section .pattern-layer-two {
    position: absolute;
    left: 0px;
    bottom: 150px;
    width: 446px;
    height: 406px;
    background-repeat: no-repeat;
}

.testimonial-section .inner-container {
    position: relative;
}

.testimonial-section .inner-container .pattern-layer {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    height: 519px;
    background-position: center top;
    background-repeat: no-repeat;
}

/***

====================================================================
	News Section
====================================================================

***/

/***

====================================================================
	Clients Section
====================================================================

***/

.clients-section {
    position: relative;
    padding-top: 20px;
    padding-bottom: 60px;
    /* height: 500px; */
    /* padding-top:100px;
	padding-bottom:100px; */
}

/* .clients-section .sponsors-outer .owl-dots,
.clients-section .sponsors-outer .owl-nav {
    position: relative;
    display: none;
} */

.clients-section .sponsors-outer .image-box {
    position: relative;
    text-align: center;
    margin: 0px;
}

.clients-section .sponsors-outer .image-box img {
    max-width: 100%;
    width: auto;
    margin: 0 auto;
    display: inline-block;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.clients-section .sponsors-outer .image-box img:hover {
    opacity: 0.7;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

/***

====================================================================
	Main Footer
====================================================================

***/

.main-footer {
    position: relative;
    overflow: hidden;
    background: #245e8e;
}

.main-footer .widgets-section {
    position: relative;
    padding: 70px 0px 4px;
}

.main-footer .circle-layer {
    position: absolute;
    right: -400px;
    bottom: -400px;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    background-color: #ffe00c;
}

.main-footer .pattern-layer-one {
    position: absolute;
    left: 0px;
    bottom: -80px;
    width: 256px;
    height: 392px;
    background-repeat: no-repeat;
}

.main-footer .pattern-layer-two {
    position: absolute;
    left: 140px;
    bottom: 40px;
    width: 69px;
    height: 69px;
    background-position: center top;
    background-repeat: no-repeat;
}

.main-footer .pattern-layer-three {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 292px;
    height: 120px;
    background-repeat: no-repeat;
}

.main-footer .pattern-layer-four {
    position: absolute;
    right: 140px;
    bottom: 40px;
    width: 69px;
    height: 69px;
    background-position: center top;
    background-repeat: no-repeat;
}

.main-footer .footer-widget {
    position: relative;
    z-index: 1;
    margin-bottom: 40px;
}

.main-footer .footer-column h5 {
    position: relative;
    font-weight: 700;
    color: #a9dbfd;
    line-height: 1.2em;
    margin-bottom: 25px;
    margin-top: 30px;
    text-transform: capitalize;
}

.main-footer .logo-widget {
    position: relative;
}

.main-footer .logo-widget .logo {
    position: relative;
    margin-bottom: 15px;
}

.main-footer .logo-widget .social-box {
    position: relative;
}

.main-footer .logo-widget .social-box li {
    position: relative;
    margin-right: 8px;
    display: inline-block;
}

.main-footer .logo-widget .social-box li a {
    position: relative;
    color: #ffffff;
    font-size: 15px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    border-radius: 50px;
    display: inline-block;
    background-color: #55acee;
}

.main-footer .logo-widget .social-box li a::before {
    -webkit-transition: all 600ms ease;
    -ms-transition: all 600ms ease;
    -o-transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    transition: all 600ms ease;
    display: inline-block;
}

.main-footer .logo-widget .social-box li a:hover::before {
    transform: rotateY(360deg);
}

.main-footer .logo-widget .social-box li.pinterest a {
    background-color: #bd081c;
}

.main-footer .logo-widget .social-box li.facebook a {
    background-color: #3b5999;
}

.main-footer .logo-widget .social-box li.dribbble a {
    background-color: #ea4c89;
}

.main-footer .logo-widget .text {
    position: relative;
    color: #00184f;
    font-size: 20px;
    line-height: 1.6em;
    margin-top: 30px;
}

.main-footer .list {
    position: relative;
}

.main-footer .list li {
    position: relative;
    margin-bottom: 8px;
}

.main-footer .list li a {
    position: relative;
    color: #fff;
    font-size: 16px;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.main-footer .list li a:hover {
    color: #e61735;
}

/* Subscribe Form */

.main-footer .footer-bottom {
    position: relative;
    padding: 25px 0px;
}

.main-footer .footer-bottom .copyright {
    position: relative;
    color: #fff;
    font-size: 16px;
}

/***

====================================================================
	Banner Section Two
====================================================================

***/

/***

====================================================================
	Professional Section
====================================================================

***/

/***

====================================================================
	Feature Section Two
====================================================================

***/

/***

====================================================================
	Event Section
====================================================================

***/

/***

====================================================================
	Testimonial Section Two
====================================================================

***/

.testimonial-block-two {
    position: relative;
    margin-bottom: 30px;
}

.testimonial-block-two .inner-box {
    position: relative;
    padding: 45px 25px 50px;
}

.testimonial-block-two .inner-box:hover::before {
    -ms-transform: skewY(2deg);
    transform: skewY(2deg);
}

.testimonial-block-two .inner-box:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 8px;
    background-color: #ffffff;
    -ms-transform: skewY(-2deg);
    transform: skewY(-2deg);
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.testimonial-block-two .inner-box .text {
    position: relative;
    color: #06092d;
    font-size: 20px;
    line-height: 1.5em;
    margin-bottom: 30px;
}

/***

====================================================================
	Goal Section
====================================================================

***/

/* Fact Counter */

/***

====================================================================
	Contact Section
====================================================================

***/

/* Custom Select */

/***

====================================================================
	Banner Section Three
====================================================================

***/

/***

====================================================================
	Team Section
====================================================================

***/

.team-section {
    position: relative;
    overflow: hidden;
    /* padding:120px 0px 60px; */
    padding: 60px 0px 0px;
    background-color: #f3fbf7;
}

.team-section .inner-container {
    position: relative;
}

.team-section .pattern-layer {
    position: absolute;
    left: 80px;
    bottom: -50px;
    width: 667px;
    height: 120px;
}

.team-section .title-column {
    position: relative;
    margin-bottom: 40px;
}

.team-section .title-column .inner-column {
    position: relative;
}

.team-section .blocks-column {
    position: relative;
    margin-bottom: 40px;
}

.team-section .blocks-column .inner-column {
    position: relative;
}

.team-block {
    position: relative;
    margin-bottom: 30px;
}

.team-block .inner-box {
    position: relative;
    padding: 35px 35px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.team-block .inner-box .image {
    position: relative;
    overflow: hidden;
    /*background-color:#2ACEE8;*/
}

.team-block .inner-box .image a {
    display: block;
    max-height: 200px;
    text-align: center;
}

.team-block .inner-box .image img {
    position: relative;
    max-width: 100%;
    display: block;
    -webkit-transition: all 600ms ease;
    -ms-transition: all 600ms ease;
    -o-transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    transition: all 600ms ease;
    max-height: 200px;
    margin: 0 auto;
}

.team-block .inner-box:hover .image img {
    opacity: 0.7;
    transform: scale(1.05, 1.05) rotate(2deg);
}

.team-block .inner-box .lower-content {
    position: relative;
    padding-top: 30px;
}

.team-block .inner-box .lower-content h5 {
    position: relative;
    font-weight: 700;
    line-height: 1.2em;
}

.team-block .inner-box .lower-content h5 a {
    position: relative;
    color: #06092d;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.team-block .inner-box .lower-content h5 a:hover {
    color: #2acee8;
}

.team-section p,
.page-title-section.bg-light p {
    color: #555555;
}

/***

====================================================================
	Page Title Section
====================================================================

***/

.page-title-section {
    position: relative;
    /* padding-bottom:130px; */
    /* padding-bottom: 60px; */
    background-color: #e9faff;
    /* padding-top:30px; */
    /* padding-top: 30px; */
}

.page-title-section .content-box {
    position: relative;
    text-align: center;
}

.page-title-section .content-box h2 {
    position: relative;
    color: #06142d;
    font-weight: 700;
    line-height: 1.2em;
}

/***

====================================================================
	Faq Blocks Section
====================================================================

***/

.faq-block {
    position: relative;
    margin-bottom: 30px;
}

.faq-block .inner-box {
    position: relative;
    text-align: center;
    padding: 50px 30px 40px;
    background-color: #eee1e0;
}

.faq-block .inner-box .icon-box {
    position: relative;
    font-size: 64px;
    color: #c56b66;
    line-height: 1em;
}

.faq-block .inner-box h5 {
    position: relative;
    margin-top: 20px;
    color: #06142d;
}


.nav-tabs .nav-link.active {
    color: #fff;
    background-color: #245e8e;
    border-color: #245e8e;
    border-radius: 10px;
}

.nav-tabs .nav-link {
    border-bottom: 4px solid #245e8e;
    margin: 10px;
    color: #245e8e;
}

.nav-tabs {
    border-bottom: 0px;
}

.card {
    border-radius: 15px;
}

.card-header {
    border-radius: 15px 15px 0 0 !important;
}

.course-session-details {
    position: relative;
    text-align: left;
    overflow: hidden;
    padding: 0px 5px 10px 5px;
    height: 100%;
    float: left;
    width: 100%;
    cursor: pointer;
    font-weight: 400;
    line-height: 1.4;
    font-size: 14px;
}

.course-short-info {
    background: #fff;
    transition: all 0.5s ease-out;
    transform: translateX(-100%);
    position: absolute;
    line-height: 1.2;
    text-align: left;
    color: #333;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    padding: 0px 10px;
    overflow: hidden;
}

.course-session-details:hover .course-short-info {
    transform: translateX(0);
}

/*--Custom CSS written by dev team RKS--*/

/******SITEMAP*****/



.register-btn,
.login-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

.register-btn .days {
    color: #dc3545;
    background: transparent;
    padding: 5px 10px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.register-btn .trial {
    background: #dc3545;
    color: #fff;
    padding: 5px 10px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.register-btn:hover .days {
    background-color: #dc3545;
    color: #fff;
}

.register-btn:hover .trial {
    background: transparent;
    color: #dc3545;
}


.login-btn .days {
    color: #206190;
    background: transparent;
    padding: 5px 10px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.login-btn .trial {
    background: #206190;
    color: #fff;
    padding: 5px 10px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-btn:hover .days {
    background-color: #206190;
    color: #fff;
}

.login-btn:hover .trial {
    background: transparent;
    color: #206190;
}

/* forma validations */

/* The snackbar - position it at the bottom and in the middle of the screen */

/* Show the snackbar when clicking on a button (class added with JavaScript) */

/* Animations to fade the snackbar in and out */

@media only screen and (max-width: 1340px) {

    .page-wrapper {
        overflow: hidden;
    }



}

@media only screen and (max-width: 1140px) {

    .main-header {
        margin: 0px !important;
    }

    .page-wrapper {
        overflow: hidden;
    }

    .main-header .outer-box {
        right: 15px;
    }

    .course-block .inner-box .lower-content h4 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 990px) and (min-width: 768px) {
    .main-header .outer-box {
        top: -12px;
        right: 0px;
        margin-right: 5px !important;
    }
}

@media only screen and (max-width: 1023px) {

    .main-header .outer-box {
        display: block;
    }

    .main-header {
        position: relative;
        background-color: #222222;
    }

    .sticky-header .logo {
        width: 100%;
        text-align: center;
    }

    .sticky-header .pull-right {
        width: 100%;
    }

    .sticky-header {
        display: none;
    }

    .feature-section .content-column .inner-column {
        padding-top: 0px;
    }

    .main-footer .footer-bottom .copyright {
        text-align: center;
        margin-bottom: 8px;
    }

}

@media only screen and (max-width: 767px) {

    .feature-section video {
        max-width: 100% !important;
    }

    .team-section .team-block .image a img {
        border-radius: 5px;
        /* max-height: unset !important; */
    }

    .team-section .team-block .lower-content {
        text-align: center;
    }

    .home-contact-section #divJoin {
        width: 100% !important;
    }

    .home-contact-section #divJoin .form-inline .form-group {
        margin-right: 0px !important;
        width: 50%;
        margin-left: 0px !important;
        margin-bottom: 20px;
    }

    .home-contact-section #divJoin .form-inline {
        justify-content: center;
    }

    .home-contact-section #divJoin .form-inline .form-control {
        margin: 0 auto;
    }

    .main-header .sticky-header .outer-box {
        display: block;
        right: 15px;
        left: auto;
        top: 0px;
    }

    .main-menu {
        width: 100%;
        display: none;
    }

    .main-header .outer-box {
        position: absolute;
        left: 0px;
        right: auto;
        margin-left: 0px;
        padding: 8px 0px;
    }
}

@media only screen and (max-width: 479px) {
    .course-block .inner-box .lower-content h4 {
        font-size: 20px;
    }

    .page-title-section .content-box h2 {
        font-size: 30px;
    }

}

.gap-30 {
    gap: 30px;
}


/**
 * Owl Carousel v2.2.0
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1;
}

.owl-carousel .animated {
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}


ul {
    padding: 0;
    margin: 0;
    list-style: none
}


/* Animation */


.image-wrapper {
    position: relative;
    width: 100%;
    padding-top: 75%;
    overflow: hidden;
}

.loading-skelton .loading-image img {
    opacity: 0.1;
}

.loading-skelton .loading-text,
.loading-skelton .inner-box .image,
.loading-skelton .loading-image,
.loading-skelton .loading-btn {
    background: #cccccc;
    color: #cccccc !important;
    border: none;
    background-image: -webkit-linear-gradient(left,
            #cccccc 0px,
            #d5d5d5 40px,
            #cccccc 80px);
    background-image: -o-linear-gradient(left,
            #cccccc 0px,
            #d5d5d5 40px,
            #cccccc 80px);
    background-image: linear-gradient(90deg,
            #cccccc 0px,
            #d5d5d5 40px,
            #cccccc 80px);
    background-size: 250px;
    border-radius: 5px !important;
    -webkit-animation: shine-loading-container-items 2s infinite ease-out;
    animation: shine-loading-container-items 2s infinite ease-out;
}

@-webkit-keyframes shine-loading-container-items {
    0% {
        background-position: -100px;
    }

    40%,
    100% {
        background-position: 140px;
    }
}

@keyframes shine-loading-container-items {
    0% {
        background-position: -100px;
    }

    40%,
    100% {
        background-position: 140px;
    }
}

.btn-outline-danger-contrast {
    color: #9f2632;
    border-color: #dc3545;
}



#callbell-iframe {
    bottom: 50px !important;
    right: -10px !important;
}

.owl-carousel {
    touch-action: manipulation;
}


.owl-carousel {
    display: block;
}

.owl-carousel .slide-owl-wrap:not(:first-child) {
    display: none;
}

.owl-carousel img {
    width: 100%;
}

.topbar-mobile-view .btn-action {
    border: 1px solid transparent;
    cursor: pointer;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.42857143;
    margin-bottom: 0;
    padding: 0.25rem 0.5rem;
    text-align: center;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    background: #206190;
    color: #fff !important;
}

@media(min-width:992px) {

    .main-header .dropdown .dropdown-menu>ul>li ul {
        background-clip: padding-box;
        background-color: #fff;
        border: 0px solid rgba(0, 0, 0, .15);
        border-radius: 0.25rem;
        color: #212529;
        display: block;
        font-size: 1rem;
        left: 100%;
        list-style: none;
        margin: 0rem 0 0;
        min-width: 10rem;
        padding: 0.5rem 0;
        position: unset !important;
        text-align: left;
        z-index: 1000;
    }

    .main-header .dropdown .dropdown-menu>ul>li>ul.submenutoggleInner {
        display: flex;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu>.submenutoggle>.dropdown>a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 18px;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu>.submenutoggle>.dropdown>a:after {
        display: none;
    }

    .main-header .dropdown .dropdown-menu>ul>li:hover>ul,
    .main-header .dropdown .dropdown-menu>ul>li>ul>li:hover>ul,
    .main-header .dropdown .dropdown-menu>ul>li>ul>li>ul>li:hover>ul {
        left: 100%;
        opacity: 1;
        top: 0;
        /*    transform: rotateX(0deg);*/
        /* transition: all .3s ease;
-moz-transition: all .3s ease;
-webkit-transition: all .5s ease;
-ms-transition: all .3s ease;
-o-transition: all .3s ease;*/
        visibility: visible;
    }

    .main-header .dropdown:hover .dropdown-menu {
        top: 100%;
        opacity: 1;
        left: 50%;
        visibility: visible;
        display: block;
        transform: translate(-50%, 0);
    }

    .main-header .dropdown .dropdown-menu>ul>li>ul>li>a {
        font-size: 15px !important;
        line-height: 16px;
        white-space: nowrap;
    }

    .main-header .dropdown .dropdown-menu>ul>li>ul>li>a:after {
        display: none !important;
    }

    .main-header .dropdown .dropdown-menu>ul>li>ul>li>ul li a {
        font-size: 12px;
        line-height: 15px;
    }
}

.main-header .dropdown .dropdown-menu>ul>li>ul>li>ul>li>ul {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    display: block;
    left: 120%;
    padding: 20px 15px;
    position: absolute;
    top: 0;
    /* transition: all .9s ease;
-moz-transition: all .9s ease;
-webkit-transition: all .9s ease;
-ms-transition: all .9s ease;
-o-transition: all .9s ease;*/
    width: 230px;
    z-index: 100;
    padding: 0px;
}

.main-header .dropdown .dropdown-menu>ul>li>ul>li>ul>li>ul>li>a {
    color: #7c7b7b;
    display: block;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    padding: 6px 18px 6px 10px;
    position: relative;
    text-align: left;
    text-transform: capitalize;
}

.main-header .dropdown .dropdown-menu>ul>li>ul>li>ul>li>ul>li>a,
.main-header .dropdown .dropdown-menu>ul>li>ul>li>ul>li>ul>li>a:before {
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}

.main-header .dropdown .dropdown-menu>ul>li>ul>li>ul>li>ul>li:hover>a {
    color: #2acee8;
    padding-left: 30px;
}

.dropdown-btn {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    width: 30px;
    height: 40px;
    justify-content: center;
}

.navbar-expand-lg .navbar-nav .dropdown-menu>ul {
    width: 100%;
}

.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown {
    width: 100%;
}

.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown ul.show {
    position: unset;
    transform: unset;
    visibility: visible;
    opacity: 1;
    border: 0px;
}

@media (min-width:768px) {
    .main-header .dropdown-btn {
        display: none !important;
    }

    .navbar-expand-lg .navbar-nav .dropdown>a:after {
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        border-right: 0.3em solid transparent;
        border-top: 0.3em solid;
        content: "";
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown>a:after {
        border-bottom: 0;
        border-top: 0.3em solid transparent;
        border-bottom: 0.3em solid transparent;
        border-left: 0.3em solid;
        content: "";
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
    }
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    padding: 0px;
}

.navbar-expand-lg .navbar-nav .dropdown>a:focus+.dropdown-menu {
    opacity: 1;
    display: flex;
    visibility: visible;
}

.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown>a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}


.custom-navbar li a {
    font-size: 15px;
    padding: 6px 16px;
    display: block;
    text-align: left;
}

.custom-navbar li {
    position: relative;
}

@media (max-width:991px) {
    .submenutoggleInner .dropdown ul {
        display: none !important;
    }

    .submenutoggleInner .dropdown.show>ul {
        display: block !important;
        padding-left: 30px;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu .submenutoggle>.dropdown>a:after {
        display: none;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu .submenutoggle>.dropdown>a {
        font-size: 18px;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu .submenutoggle>.dropdown .submenutoggleInner .dropdown .dropdown-btn {
        display: none;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu .submenutoggle>.dropdown .submenutoggleInner .dropdown>ul {
        display: block !important;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu .submenutoggle>.dropdown .submenutoggleInner .dropdown>ul li a {
        font-size: 12px;
        line-height: 16px;
        padding-left: 30px;
    }

    /*.navbar-expand-lg .navbar-nav .dropdown-menu .submenutoggle > .dropdown > .dropdown-btn{
display: none;
}*/
}

@media only screen and (max-width: 600px) {
    .topbar-mobile-view a {
        font-size: 9px !important;
    }
}


@media screen and (max-width: 567px) {
    .not-mobile-display {
        display: none !important;
    }

    .mobile-only {
        display: block !important;
    }

    .chnage_width {
        font-size: 11px;
        padding: 7px 39px;
    }
}

.mobile-only {
    display: none;
}

.not-mobile-display {
    display: block;
}

.banner-section .owl-dots {
    text-align: right !important;
}

.whychoosehome {
    padding: 100px 0;
    width: 100%;
    display: block;
    background-color: #f3fbf7;
    color: #0d3a4f
}

.whychoosehome .card {
    background: none;
    border: 0px;
    height: 100%;
}

.whychoosehome .card img {
    max-height: 80px;
    margin-bottom: 16px;
}

.whychoosehome .card h3 {
    font-size: 24px;
    margin: 0px 0px 20px;
}

.whychoosehome .card ul {
    list-style: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: left;
}

.whychoosehome .card ul li {
    font-size: 16px;
    display: flex;
    gap: 8px;
    font-weight: 500;
}

.whychoosehome .card ul li img {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
}

.whychoosehome .card ul.teachers {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
}

.whychoosehome .card ul.teachers li {
    width: calc(50% - 4px);
    border-radius: 8px;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    align-items: center;
    background: #fff;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, .24);
    padding: 8px;
}

.whychoosehome .card ul.teachers li img {
    width: 34px;
    height: 34px;
    border-radius: 34px;
    flex: 0 0 34px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, .3);
    margin: 0px;
}

.whychoosehome .card ul.teachers li span {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    line-height: 14px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.whychoosehome .card ul.teachers li span strong {
    width: 100%;
    font-weight: 600;
}


.image-wrapper {
    position: relative;
    width: 100%;
    padding-top: 75%;
    overflow: hidden;
}

.aspect-ratio-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.loading-skelton .loading-content {
    background: #f7f7f7;
    padding: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.loading-skelton .loading-image img {
    opacity: 0.1;
}

.loading-skelton .loading-text,
.loading-skelton .inner-box .image,
.loading-skelton .loading-image,
.loading-skelton .image-holder,
.loading-skelton .loading-btn {
    background: #cccccc;
    color: #cccccc !important;
    border: none;
    background-image: -webkit-linear-gradient(left,
            #cccccc 0px,
            #d5d5d5 40px,
            #cccccc 80px);
    background-image: -o-linear-gradient(left,
            #cccccc 0px,
            #d5d5d5 40px,
            #cccccc 80px);
    background-image: linear-gradient(90deg,
            #cccccc 0px,
            #d5d5d5 40px,
            #cccccc 80px);
    background-size: 250px;
    border-radius: 5px !important;
    -webkit-animation: shine-loading-container-items 2s infinite ease-out;
    animation: shine-loading-container-items 2s infinite ease-out;
}

@-webkit-keyframes shine-loading-image {
    0% {
        background-position: -32px;
    }

    40%,
    100% {
        background-position: 208px;
    }
}

@keyframes shine-loading-image {
    0% {
        background-position: -32px;
    }

    40%,
    100% {
        background-position: 208px;
    }
}

@-webkit-keyframes shine-loading-container-items {
    0% {
        background-position: -100px;
    }

    40%,
    100% {
        background-position: 140px;
    }
}

@keyframes shine-loading-container-items {
    0% {
        background-position: -100px;
    }

    40%,
    100% {
        background-position: 140px;
    }
}

.text-danger-contrast {
    color: #9e2632;
}

.btn-outline-danger-contrast {
    color: #9f2632;
    border-color: #dc3545;
}


#callbell-iframe {
    bottom: 50px !important;
    right: -10px !important;
}


.preloader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #ffffff;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("https://cdnbeta.explico.sg/v5/assets/images/explico-cmyk-tagline-logo@2x.png");
    background-size: 140px;
}


.owl-carousel {
    touch-action: manipulation;
}


.owl-carousel {
    display: block;
}

.owl-carousel .slide-owl-wrap:not(:first-child) {
    display: none;
}

.owl-carousel img {
    width: 100%;
}

.bc {
    width: 100%;
    padding-right: 1.6rem;
    padding-left: 1.6rem;
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 768px) {

    .bc {
        max-width: 1280px
    }

    section {
        padding: 3rem 0
    }
}


.bc {
    width: 100%;
    padding-right: 1.6rem;
    padding-left: 1.6rem;
    margin-right: auto;
    margin-left: auto
}

.bgw {
    background-color: #fff
}

.bgt {
    background-color: #009688;
    color: #fff
}

.es {
    box-shadow: 0 1px 2px 0 #3c40434d, 0 2px 6px 2px #3c404326
}

.eba {
    display: inline-block;
    padding: .45em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    margin-bottom: 1rem
}

.tc {
    text-align: center
}

@media (min-width: 768px) {

    .bc {
        max-width: 1280px
    }

    section.l {
        padding: 10rem 0
    }
}

.partnerslg div {
    display: inline-block;
    /* margin: .3rem; */
    background: #fff;
    line-height: 0;
    padding: .6rem
}

.partnerslg div img {
    height: 100px;
}


footer {
    content-visibility: auto
}

footer h4 {
    color: #a9dbfd;
    font-weight: 700;
}

#assessmentContainer {
    margin-top: -175px;
}

@media (min-width: 375px) {
    #assessmentContainer {
        margin-top: -100px;
    }
}

@media (min-width: 425px) {
    #assessmentContainer {
        margin-top: -50px;
    }
}

@media (min-width: 425px) {
    #assessmentContainer {
        margin-top: -50px;
    }
}

@media (min-width: 425px) {
    #assessmentContainer {
        margin-top: 20px;
    }
}

@media (min-width: 801px) {
    #assessmentContainer {
        margin-top: -200px;
    }
}

@media (min-width: 1024px) {
    #assessmentContainer {
        margin-top: -100px;
    }
}

@media (min-width: 1250px) {
    #assessmentContainer {
        margin-top: -50px;
    }
}

@media (min-width: 1250px) {
    #assessmentContainer {
        margin-top: 0px;
    }
}

@media (min-width: 1250px) {
    #assessmentContainer {
        margin-top: 50px;
    }
}

hr.center-diamond {
    background: #206190;
    border: 0;
    height: 2px;
    width: 100px;
    position: relative;
    margin: 30px auto;
}
hr.center-diamond:before {
    content: "";
    width: 12px;
    height: 12px;
    background: #206190;
    display: inline-block;
    border: 2px solid #206190;
    position: absolute;
    top: -5px; /* Adjust this value to move the diamond line up or down */
    left: 47%;
    margin: 0 0 0 -3px;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Opera, Chrome, and Safari */
}